<template>
  <tr>
    <template v-for="(header, index) in headers">
      <article-siblings-table-column
        :key="index"
        :website-id="header.id"
        :article-cluster-id="articleClusterId"
      />
    </template>
  </tr>
</template>
<script type="text/babel">
import ArticleSiblingsTableColumn from "@/components/article/ArticleSiblingsTableColumn";
export default {
  name: "article-siblings-table-row",
  components: { ArticleSiblingsTableColumn },
  props: {
    articleClusterId: {
      type: Number,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  },
  computed: {},
  methods: {}
};
</script>
