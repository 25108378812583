<template>
  <div>
    <table-overview
      title="Siblings"
      store="website/siblings"
      :headers="siblingHeaders"
      force-initial-sort="id"
      :state-in-hash="false"
      searchable
    >
      <website-siblings-table-row
        slot="table-row"
        slot-scope="{ item }"
        :article-cluster-id="item"
        :headers="siblingHeaders"
      />
    </table-overview>

    <table-overview
      :title="`Articles without siblings (${website.host})`"
      store="website/siblings/onlyChildren"
      :headers="[
        { text: 'Path', value: 'path' },
        { text: 'Title', value: 'title' },
        {
          text: 'Details',
          align: 'center',
          sortable: false,
          class: 'v-table-th--icon',
          rolesOrPermissions: 'article_view'
        }
      ]"
      force-initial-sort
      :state-in-hash="false"
      searchable
    >
      <website-siblings-only-children-table-row
        slot="table-row"
        slot-scope="{ item }"
        :website-id="website.id"
        :article-id="item"
      />
    </table-overview>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import WebsiteSiblingsTableRow from "@/components/website/WebsiteSiblingsTableRow";
import TableOverview from "@/components/generic/overview/TableOverview";
import WebsiteSiblingsOnlyChildrenTableRow from "@/components/website/WebsiteSiblingsOnlyChildrenTableRow";

const { mapGetters } = createNamespacedHelpers("website/siblings");

export default {
  name: "websites-siblings",
  components: {
    WebsiteSiblingsOnlyChildrenTableRow,
    WebsiteSiblingsTableRow,
    TableOverview
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters(["siblingHeaders"]),
    website() {
      return this.$store.state.website.model;
    }
  }
};
</script>
