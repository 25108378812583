<template>
  <v-container fluid>
    <v-text-field
      v-model="googleTrackingId"
      :disabled="!hasRolesOrPermissions('website_edit')"
      v-validate="'max:255'"
      label="Google Tracking ID"
      placeholder="UA-012..."
      :counter="255"
      data-vv-name="Google tracking ID"
      :error-messages="errors.collect('googleTrackingId')"
    />
    <v-text-field
      v-model="googleViewId"
      :disabled="!hasRolesOrPermissions('website_edit')"
      v-validate="'max:255'"
      label="Google View ID"
      placeholder="012345..."
      :counter="255"
      data-vv-name="Google View ID"
      :error-messages="errors.collect('googleViewId')"
    />
    <v-divider class="my-4" />
    <v-text-field
      label="Siteplan link"
      v-model="siteplanLink"
      :disabled="!hasRolesOrPermissions('website_edit')"
      v-validate="'max:255'"
      :counter="255"
      data-vv-name="siteplanLink"
      :error-messages="errors.collect('siteplanLink')"
    />
    <v-text-field
      label="Siteplan link editable"
      v-model="siteplanLinkEditable"
      :disabled="!hasRolesOrPermissions('website_edit')"
      v-validate="'max:255'"
      :counter="255"
      data-vv-name="siteplanLinkEditable"
      :error-messages="errors.collect('siteplanLinkEditable')"
    />
    <v-divider class="my-4" />
    <v-select-locale
      v-model="locales"
      multiple
      data-vv-rules="required|min:1"
      data-vv-name="locales"
      :error-messages="errors.collect('locales')"
    />
    <v-switch
      label="Is searchable on Buldit.be"
      v-model="isSearchable"
      :disabled="!hasRolesOrPermissions('website_edit')"
    />
    <v-select
      label="Protocol"
      v-model="protocol"
      data-vv-rules="required|min:1"
      data-vv-name="locales"
      :error-messages="errors.collect('locales')"
      :items="[
        {
          text: 'HTTPS',
          value: 'HTTPS'
        },
        {
          text: 'HTTP',
          value: 'HTTP'
        }
      ]"
    />
  </v-container>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VSelectLocale from "@/components/locale/VSelectLocale";

const { mapComputed } = createNamespacedHelpers("website");

export default {
  name: "website-update",
  inject: ["$validator"],
  components: { VSelectLocale },
  data: () => ({}),
  computed: {
    ...mapComputed([
      "googleTrackingId",
      "googleViewId",
      "siteplanLink",
      "siteplanLinkEditable",
      "locales",
      "isSearchable",
      "protocol"
    ])
  },
  methods: {
    hasRolesOrPermissions(permission) {
      return this.$store.getters["auth/hasRolesOrPermissions"](permission);
    }
  }
};
</script>
