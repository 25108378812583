<template>
  <v-dialog
    lazy
    v-model="internalDialog"
    max-width="600px"
    :persistent="initialColor !== color || initialRemark !== remark"
  >
    <v-card>
      <v-toolbar color="primary" dense>
        <v-toolbar-title class="white--text">{{ articleUrl }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon color="white" @click="close">close</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon color="white" @click="save">far fa-fw fa-save</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-select
          v-model="selectedRemark"
          :items="presetRemarks"
          item-text="text"
          item-value="value"
          label="Remark"
          return-object
          single-line
        ></v-select>
        <v-text-field
          v-if="
            selectedRemark.value === null ||
              selectedRemark.value === 'Artikel herschreven (voeg datum toe)'
          "
          label="Remark"
          v-model="remark"
        ></v-text-field>
        <v-text-field
          v-if="selectedRemark.value === null"
          v-model="color"
          type="color"
          label="Color"
        />
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
import api from "@/api";
import { isNil } from "lodash";
export default {
  name: "website-siblings-table-dialog",
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    articleSiblingId: {
      type: Number,
      required: true
    },
    websiteId: {
      type: Number,
      required: true
    },
    articleUrl: {
      type: String,
      required: false
    }
  },
  data: () => ({
    presetRemarks: [
      { text: "Niet vertalen", value: "Niet vertalen", color: "#bcd4e6" },
      {
        text: "Artikel herschreven",
        value: "Artikel herschreven (voeg datum toe)",
        color: "#6d9cbf"
      },
      { text: "Custom", value: null, color: "#ffffff" }
    ],
    selectedRemark: {},
    initialRemark: null,
    initialColor: null
  }),
  created() {
    this.init();
  },
  watch: {
    selectedRemark: {
      deep: true,
      handler(newValue) {
        if (!isNil(newValue)) {
          this.remark = newValue.value;
          this.color = newValue.color;
        } else {
          this.remark = this.initialRemark;
          this.color = this.initialColor;
        }
      }
    }
  },
  computed: {
    internalDialog: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("close-dialog");
      }
    },
    remark: {
      get() {
        return this.$store.getters["website/siblings/articleSiblingRemark"](
          this.articleSiblingId
        );
      },
      set(value) {
        this.$store.commit("website/siblings/setArticleArticleRemark", {
          remark: value,
          articleSiblingId: this.articleSiblingId
        });
      }
    },
    color: {
      get() {
        return this.$store.getters["website/siblings/articleSiblingColor"](
          this.articleSiblingId
        );
      },
      set(value) {
        this.$store.commit("website/siblings/setArticleArticleColor", {
          color: value,
          articleSiblingId: this.articleSiblingId
        });
      }
    }
  },
  methods: {
    async save() {
      await api.website.saveArticleArticleData(
        this.articleSiblingId,
        this.remark,
        this.color
      );
      this.internalDialog = false;
      this.initialColor = this.color;
      this.initialRemark = this.remark;
    },
    init() {
      this.initialRemark = this.$store.getters[
        "website/siblings/articleSiblingRemark"
      ](this.articleSiblingId);
      this.initialColor = this.$store.getters[
        "website/siblings/articleSiblingColor"
      ](this.articleSiblingId);
    },
    close() {
      this.remark = this.initialRemark;
      this.color = this.initialColor;
      this.internalDialog = false;
    }
  }
};
</script>
