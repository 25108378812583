<template>
  <div>
    <table-overview
      title="Siblings"
      store="article/siblings"
      :headers="siblingHeaders"
      force-initial-sort="id"
      searchable
    >
      <article-siblings-table-row
        slot="table-row"
        slot-scope="{ item }"
        :article-cluster-id="item"
        :headers="siblingHeaders"
      />
    </table-overview>
    <v-toolbar color="light-blue" class="primary" dense>
      <v-toolbar-title class="white--text">
        Articles without siblings ({{ website.host }})
      </v-toolbar-title>
    </v-toolbar>
    <v-data-table
      :headers="[
        { text: 'Path', value: 'path' },
        { text: 'Title', value: 'title' },
        {
          text: 'Details',
          align: 'center',
          sortable: false,
          class: 'v-table-th--icon',
          rolesOrPermissions: 'article_view'
        }
      ]"
      :items="ids"
      :pagination.sync="pagination"
      :total-items="pagination.totalItems"
      :rows-per-page-items="[20, 50, 100]"
      disable-page-reset
      :loading="isLoading || isError"
      class="overview-datatable elevation-1"
    >
      <v-progress-linear
        slot="progress"
        :color="isError ? 'error' : 'accent'"
        indeterminate
        :height="3"
      />
      <highlander-articles-table-row
        slot="items"
        slot-scope="props"
        :article-id="props.item"
      />
    </v-data-table>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import TableOverview from "@/components/generic/overview/TableOverview";
import ArticleSiblingsTableRow from "@/components/article/ArticleSiblingsTableRow";
import {
  IDS as GETTER_IDS,
  IS_ERROR as GETTER_IS_ERROR,
  IS_LOADING as GETTER_IS_LOADING,
  PAGINATION as GETTER_PAGINATION
} from "../../store/templates/table/getter-types";
import { SET_PAGINATION as DISPATCH_SET_PAGINATION } from "../../store/templates/table/action-types";
import HighlanderArticlesTableRow from "../../components/article/HighlanderArticlesTableRow";

const { mapGetters } = createNamespacedHelpers("article/siblings");

export default {
  name: "article-siblings",
  components: {
    HighlanderArticlesTableRow,
    ArticleSiblingsTableRow,
    TableOverview
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["siblingHeaders"]),
    website() {
      return this.$store.getters["article/website"];
    },
    pagination: {
      get() {
        return this.$store.getters[
          `article/highlanderArticles/${GETTER_PAGINATION}`
        ];
      },
      set(pagination) {
        this.$store.dispatch(
          `article/highlanderArticles/${DISPATCH_SET_PAGINATION}`,
          {
            pagination
          }
        );
      }
    },
    isLoading() {
      return this.$store.getters[
        `article/highlanderArticles/${GETTER_IS_LOADING}`
      ];
    },
    isError() {
      return this.$store.getters[
        `article/highlanderArticles/${GETTER_IS_ERROR}`
      ];
    },
    ids() {
      return this.$store.getters[`article/highlanderArticles/${GETTER_IDS}`];
    }
  },
  methods: {}
};
</script>
