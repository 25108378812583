var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-overview',{attrs:{"title":"Siblings","store":"article/siblings","headers":_vm.siblingHeaders,"force-initial-sort":"id","searchable":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
var item = ref.item;
return _c('article-siblings-table-row',{attrs:{"article-cluster-id":item,"headers":_vm.siblingHeaders}})}}])}),_c('v-toolbar',{staticClass:"primary",attrs:{"color":"light-blue","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Articles without siblings ("+_vm._s(_vm.website.host)+") ")])],1),_c('v-data-table',{staticClass:"overview-datatable elevation-1",attrs:{"headers":[
      { text: 'Path', value: 'path' },
      { text: 'Title', value: 'title' },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'article_view'
      }
    ],"items":_vm.ids,"pagination":_vm.pagination,"total-items":_vm.pagination.totalItems,"rows-per-page-items":[20, 50, 100],"disable-page-reset":"","loading":_vm.isLoading || _vm.isError},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return _c('highlander-articles-table-row',{attrs:{"article-id":props.item}})}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.isError ? 'error' : 'accent',"indeterminate":"","height":3},slot:"progress"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }