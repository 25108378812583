var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-overview',{attrs:{"title":"Siblings","store":"website/siblings","headers":_vm.siblingHeaders,"force-initial-sort":"id","state-in-hash":false,"searchable":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
var item = ref.item;
return _c('website-siblings-table-row',{attrs:{"article-cluster-id":item,"headers":_vm.siblingHeaders}})}}])}),_c('table-overview',{attrs:{"title":("Articles without siblings (" + (_vm.website.host) + ")"),"store":"website/siblings/onlyChildren","headers":[
      { text: 'Path', value: 'path' },
      { text: 'Title', value: 'title' },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'article_view'
      }
    ],"force-initial-sort":"","state-in-hash":false,"searchable":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('website-siblings-only-children-table-row',{attrs:{"website-id":_vm.website.id,"article-id":item}})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }