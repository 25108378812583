<template>
  <td
    v-bind:class="{
      malformed: articleHrefIsMalformed,
      inValid: !articleHrefIsValid && !articleHrefIsMalformed,
      sibling: isSiblingOfWebsite,
      noIndex: articleIsNoIndex
    }"
    @click="openDialog"
    v-bind:style="bgc"
  >
    <div v-if="articleUrl === null">
      <b>
        Geen vertaling
      </b>
      <br />
      <b>{{ sibling.remark }}</b>
    </div>
    <div v-else>
      <p>
        {{ `${articleUrl}${articleIsNoIndex ? " (no-index)" : ""}` }}
      </p>
      <p>
        <b>{{ sibling.remark }}</b>
      </p>
    </div>
    <article-siblings-table-dialog
      :dialog="dialog"
      :article-url="articleUrl"
      :article-sibling-id="sibling.id"
      :website-id="websiteId"
      @close-dialog="dialog = false"
    />
  </td>
</template>
<script type="text/babel">
import ArticleSiblingsTableDialog from "@/components/article/ArticleSiblingsTableDialog";
import { isNil } from "lodash";
export default {
  name: "article-siblings-table-column",
  components: { ArticleSiblingsTableDialog },
  props: {
    articleClusterId: {
      type: Number,
      required: true
    },
    websiteId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    isSiblingOfWebsite() {
      return this.websiteId === this.articleWebsite.id;
    },
    articleWebsite() {
      return this.$store.getters["article/website"];
    },
    sibling() {
      return this.$store.getters["article/siblings/siblingForWebsite"](
        this.articleClusterId,
        this.websiteId
      );
    },
    article() {
      return this.$store.getters["article/siblings/siblingArticle"](
        this.sibling.id
      );
    },
    website() {
      return this.$store.getters["article/getWebsiteById"](this.websiteId);
    },
    articleUrl() {
      return isNil(this.article)
        ? null
        : `${this.website.host}${this.article.path}`;
    },
    bgc() {
      return {
        backgroundColor: this.sibling.color
      };
    },
    border() {
      return { borderStyle: "solid", borderColor: "red" };
    },
    articleHrefIsMalformed() {
      return isNil(this.article) ? false : this.article.isHreflangMalformed;
    },
    articleHrefIsValid() {
      return isNil(this.article) ? true : this.article.isHreflangValid;
    },
    articleIsNoIndex() {
      return isNil(this.article) ? false : this.article.isNoIndexValid;
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    }
  }
};
</script>
<style scoped>
.sibling {
  background-color: gainsboro;
}
.malformed {
  border-left: 3px solid red;
}
.inValid {
  border-left: 3px solid red;
}
.noIndex {
  color: darkgray;
}
p {
  margin: 0;
}
b {
  margin: 0;
}
</style>
