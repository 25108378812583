<template>
  <td
    v-bind:class="{
      malformed: articleHrefIsMalformed,
      inValid: !articleHrefIsValid && !articleHrefIsMalformed,
      sibling: isSiblingOfWebsite,
      noIndex: articleIsNoIndex
    }"
    @click="openDialog"
    v-bind:style="bgc"
  >
    <p style="margin: 0;" v-if="articleUrl === null">
      <b>{{
        (sibling === undefined || sibling.remark === null) &&
        article.articleSiblingId !== null
          ? "Geen vertaling"
          : sibling.remark
      }}</b>
    </p>
    <div v-else>
      <p
        style="margin: 0;"
        v-html="`${articleUrl}${articleIsNoIndex ? '  (no-index)' : ''}`"
      />
      <p style="margin: 0;">
        <b>{{ sibling === undefined ? undefined : sibling.remark }}</b>
      </p>
    </div>

    <website-siblings-table-dialog
      v-if="sibling !== undefined"
      :dialog="dialog"
      :article-url="articleUrl"
      :article-sibling-id="sibling.id"
      :website-id="websiteId"
      @close-dialog="dialog = false"
    />
  </td>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import WebsiteSiblingsTableDialog from "./WebsiteSiblingsTableDialog";

export default {
  name: "website-siblings-table-column",
  // eslint-disable-next-line vue/no-unused-components
  components: { WebsiteSiblingsTableDialog },
  props: {
    articleClusterId: {
      type: Number,
      required: true
    },
    websiteId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    isSiblingOfWebsite() {
      return this.websiteId === this.$store.state.website.model.id;
    },
    sibling() {
      return this.$store.getters["website/siblings/siblingForWebsite"](
        this.articleClusterId,
        this.websiteId
      );
    },
    article() {
      return this.$store.getters["website/siblings/siblingArticle"](
        this.sibling?.id
      );
    },
    articleUrl() {
      return isNil(this.article)
        ? "<b>Geen vertaling</b>"
        : `${this.$store.state.website.model.host}${this.article.path}`;
    },
    bgc() {
      return {
        backgroundColor: this.sibling?.color
      };
    },
    border() {
      return { borderStyle: "solid", borderColor: "red" };
    },
    articleHrefIsMalformed() {
      return isNil(this.article) ? false : this.article.isHreflangMalformed;
    },
    articleHrefIsValid() {
      return isNil(this.article) ? true : this.article.isHreflangValid;
    },
    articleIsNoIndex() {
      return isNil(this.article) ? false : this.article.isNoIndexValid;
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    }
  }
};
</script>
<style scoped>
.sibling {
  background-color: gainsboro;
}

.malformed {
  border-left: 3px solid red;
}

.inValid {
  border-left: 3px solid red;
}

.noIndex {
  color: darkgray;
}

p {
  margin: 0;
}
</style>
